<template lang="pug">
  .booking-form
    .arrow-back(@click="$emit(`unselect-booking`)")
      FaIcon(
        class="fa-rotate-270"
        :icon="`chevron-up`"
      )
      span {{ $t("company_system.back") }}

    .title
      span {{ $t("company_system.booking_confirmation.title") }}

    .form 
      .form-row 
        .form-row-label
          FormFieldLabel(
            :title="$t(`${localKey}.reservation_notes.business_memo`)"
          )
        .form-row-field
          BFormInput(
            type="text"
            name="fee"
            v-model="reservation.memo"
          )

      .form-row
        .form-row-label.form-row-label_dropdown
          FormFieldLabel(
            :title="$t(`${localKey}.status`)"
            required
            :required-label="$t(`company_system.req`)"
          )
        .form-row-field
          AppDropdown(
            :class="{ 'invalid': isInvalidReservationStatus }"
            allow-empty
            close-on-select
            :value="selectedStatus"
            orderDirection="keep"
            :items="statuses"
            @select="selectStatus"
          )

      .form-row
        .form-row-label
          FormFieldLabel(
            :title="$t(`${localKey}.payment_status`)"
          )
        .form-row-field
          | {{ $t(`${localKey}.${reservation.payment_status}`) }}

      .form-row 
        .form-row-label 
          FormFieldLabel(
            :title="$t(`${localKey}.reservation_number`)"
          )
        .form-row-field
          | {{ reservation.reservation_code }}

      .form-row 
        .form-row-label 
          FormFieldLabel(
            :title="$t(`${localKey}.application_date`)"
          )
        .form-row-field
          | {{ reservation.reserved_at }}

      .form-row 
        .form-row-label 
          FormFieldLabel(
            :title="$t(`${localKey}.sales_channel`)"
          )
        .form-row-field
          | {{ reservation.sales_channel }}

      .form-row 
        .form-row-label 
          FormFieldLabel(
            :title="$t(`${localKey}.last_modified`)"
          )
        .form-row-field
          | {{ reservation.updated_at }}
    
    .title
      span {{ $t(`${localKey}.details.title`) }}

    .form
      .form-row 
        .form-row-label 
          FormFieldLabel(
            :title="$t(`${localKey}.details.rate_plan`)"
          )
        .form-row-field {{ reservation.plan_name }}

      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.car_class`)")
        .form-row-field
          | {{ reservation.car_class }}

      .form-row 
        .form-row-label 
          FormFieldLabel(
            :title="$t(`${localKey}.details.standard_options`)"
          )
        .form-row-field
          | {{ standardOptions }}

      .form-row 
        .form-row-label 
          FormFieldLabel(
            :title="$t(`${localKey}.details.selected_options`)"
          )
        .form-row-field
          | {{ selectableOptions }}

      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.reservation_person_name`)")
        .form-row-field
          | {{ reservation.reservation_person_name }}

      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.reservation_person_name_kana`)")
        .form-row-field
          | {{ reservation.reservation_person_name_kana }}

      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.contact_phone`)")
        .form-row-field
          | {{ reservation.reservation_person_phone }}

      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.emergency_phone`)")
        .form-row-field
          | {{ reservation.reservation_person_emergency_phone }}

      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.address`)")
        .form-row-field
          .form-row-field-col
            span {{ reservation.reservation_person_postal_code }}
            span {{ reservation.reservation_person_address }}

      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.email`)")
        .form-row-field
          | {{ reservation.reservation_person_email }}

      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.driver_name`)")
        .form-row-field
          | {{ reservation.driver_name }}

      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.driver_name_kana`)")
        .form-row-field
          | {{ reservation.driver_name_kana }}

      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.contact_phone`)")
        .form-row-field
          | {{ reservation.driver_phone }}

      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.emergency_phone`)")
        .form-row-field
          | {{ reservation.driver_emergency_phone }}

      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.address`)")
        .form-row-field
          | {{ reservation.driver_postal_code }}
        .form-row-field.form-row-field_without-label
          | {{ reservation.driver_address }}

      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.email`)")
        .form-row-field
          | {{ reservation.driver_email }}

      .form-row 
        .form-row-label 
          FormFieldLabel(
            :title="$t(`${localKey}.details.pick_up_shop`)"
          )
        .form-row-field
          | {{ reservation.pick_up_shop }}

      .form-row 
        .form-row-label 
          FormFieldLabel(
            :title="$t(`${localKey}.details.drop_off_shop`)"
          )
        .form-row-field
          | {{ reservation.drop_off_shop }}


      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.pick_up_date`)")
        .form-row-field
          | {{ reservation.pick_up_at }}

      .form-row.form-row_last
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.details.drop_off_date`)")
        .form-row-field
          | {{ reservation.drop_off_at }}

    .title
      span {{ $t(`${localKey}.reservation_fee_breakdown.title`) }}

    .table
      table
        thead 
          tr 
            th
              span {{ $t(`${localKey}.reservation_fee_breakdown.table.header.details`) }}
            th

        tbody
          tr 
            td 
              span {{ this.$t(`${localKey}.reservation_fee_breakdown.table.body.rental_fee`) }}
            td 
              span {{ reservation.total_fee }}
              span.yen 
                | {{ $t('general_settings.yen') }}

          tr(
            v-for="fee in optionFees"
            :class="{ sub: fee.isSub }"
            :key="fee.key"
          )
            td 
              span {{ fee.title }}
            td 
              span {{ fee.value }}
              span.yen(
                v-if="fee.value !== '-'"
              ) 
                | {{ $t('general_settings.yen') }}

          tr
            td
              span {{ this.$t(`${localKey}.reservation_fee_breakdown.table.body.late_fee`) }}
            td
              span {{ reservation.late_fee }}
              span.yen 
                | {{ $t('general_settings.yen') }}

          tr
            td
              span {{ this.$t(`${localKey}.reservation_fee_breakdown.table.body.gasoline_fee`) }}
            td
              span {{ reservation.gasoline_fee }}
              span.yen
                | {{ $t('general_settings.yen') }}

          tr
            td
              span {{ this.$t(`${localKey}.reservation_fee_breakdown.table.body.late_night_rental_fee`) }}
            td
              span {{ reservation.late_night_rental_fee }}
              span.yen
                | {{ $t('general_settings.yen') }}

          tr
            td
              span {{ this.$t(`${localKey}.reservation_fee_breakdown.table.body.drop_off_fee`) }}
            td
              span {{ reservation.drop_off_fee }}
              span.yen
                | {{ $t('general_settings.yen') }}

        tfoot
          tr
            td
              span {{ $t(`${localKey}.reservation_fee_breakdown.table.body.total_amount`) }}
            td
              span {{ tableSum }}
              span.yen {{ $t('general_settings.yen') }}


    .title
      span {{ $t(`${localKey}.reservation_notes.title`) }}

    .form 
      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.reservation_notes.number_of_passengers`)")
        .form-row-field
          | {{ reservation.passenger_number }}

      .form-row 
        .form-row-label 
          FormFieldLabel(:title="$t(`${localKey}.reservation_notes.transfer`)")
        .form-row-field
          .form-row-field-col
            span {{ reservation.shuttle }}
            span {{ reservation.transfer }}

      .form-row 
        .form-row-label 
          FormFieldLabel(
            :title="$t(`${localKey}.reservation_notes.scheduled_flight`)"
          )
        .form-row-field
          .form-row-field-col
            span {{ reservation.departure_flight_code }}
            span {{ reservation.arrival_flight_code }}

    FormActionBar(
      print-pdf
      delete-enabled
      @delete="handleDelete"
      @save="handleSave"
    )
</template>

<script>
  const LOCAL_KEY = "company_system.booking_confirmation.form"

  import { find, filter, map, reduce } from "lodash-es"

  // store modules
  import bookingConfirmationModule from "@/config/store/company_system/booking_confirmation"
  import optionsModule from "@/config/store/company_system/basic_settings/options"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withValidations from "@/mixins/withValidations"
  import withScrollTop from "@/mixins/withScrollTop"
  import withConfirmation from "@/mixins/withConfirmation"

  const bookingConfirmationMixin = withStoreModule(bookingConfirmationModule, {
    name: "bookingConfirmationMixin",
    resetState: true,
    readers: {
      reservation: "item"
    },
    actions: {
      fetchReservation: "FETCH_ITEM",
      updateReservation: "UPDATE_ITEM",
      deleteReservation: "DELETE_ITEM"
    }
  })

  const optionsMixin = withStoreModule(optionsModule, {
    name: "optionsMixin",
    readers: { options: "items" },
    actions: { fetchOptions: "FETCH_ITEMS" }
  })

  const validationsMixin = withValidations(({ required }) => ({
    reservation: {
      status: {
        required
      }
    }
  }))

  export default {
    components: {
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      FormActionBar: () => import("../FormActionBar")
    },

    mixins: [bookingConfirmationMixin, optionsMixin, validationsMixin, withConfirmation, withScrollTop],

    data() {
      return {
        optionFees: []
      }
    },

    props: {
      reservationId: {
        type: Number,
        required: true
      },
      statuses: {
        type: Array,
        default: () => []
      }
    },

    mounted() {
      this.fetchOptions({ pagination: { _disabled: true } })
      this.fetchReservation(this.reservationId).then(() => {
        this.setOptionFee()
      })
    },

    methods: {
      getOption(id) {
        return find(this.options, option => option.id === id)
      },

      selectStatus({ id }) {
        this.reservation.status = id
      },

      setOptionFee() {
        const optionFees = map(
          [...this.reservation.standard_option_ids, ...this.reservation.selectable_option_ids],
          id => {
            const option = this.getOption(id)
            const value = this.reservation.option_fees[id] || 0
            return {
              key: option.id,
              name: `option_${option.id}`,
              title: option.name,
              value,
              isSub: true
            }
          }
        )
        if (optionFees.length) {
          this.optionFees = [
            {
              key: "option",
              title: this.$t(`${LOCAL_KEY}.reservation_fee_breakdown.table.body.options`),
              value: "-",
              isSub: false
            },
            ...optionFees
          ]
        } else {
          this.optionFees = []
        }
      },

      handleSave() {
        this.validateAttributes()
        if (!this.isValid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".invalid", inline: "center" })
          })
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
        })
      },

      async sendRequest() {
        const { id, memo, status } = this.reservation
        await this.updateReservation({ id, reservation: { memo, status } })
      },

      handleDelete() {
        this.$confirm({
          title: this.$t("company_system.rate_plan_settings.delete_plan_confirmation"),
          resolve: {
            handler: () => {
              this.deleteReservation(this.reservationId)
              this.$emit(`unselect-booking`)
            }
          }
        })
      }
    },

    computed: {
      localKey() {
        return LOCAL_KEY
      },

      selectedStatus() {
        return find(this.statuses, ({ id }) => this.reservation.status === id)
      },

      standardOptions() {
        return map(
          filter(this.options, ({ id }) => this.reservation.standard_option_ids?.includes(id)),
          "name"
        ).join(", ")
      },

      selectableOptions() {
        return map(
          filter(this.options, ({ id }) => this.reservation.selectable_option_ids?.includes(id)),
          "name"
        ).join(", ")
      },

      tableSum() {
        const { total_fee, late_fee, gasoline_fee, late_night_rental_fee, drop_off_fee } = this.reservation
        let sum =
          Number(total_fee) +
          Number(late_fee) +
          Number(gasoline_fee) +
          Number(late_night_rental_fee) +
          Number(drop_off_fee)
        sum += reduce(
          this.optionFees,
          (sum, elem) => {
            if (!elem) {
              return sum
            }
            if (elem.value !== "-") {
              return sum + Number(elem.value)
            }
            return sum
          },
          0
        )
        return sum
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
  @import "@/assets/styles/variables.sass"

  .booking-form
    margin-bottom: 20px

    .flex-col
      display: flex
      flex-direction: column
      align-items: flex-start

    .arrow-back
      cursor: pointer
      margin-left: 5px
      margin-top: 10px

      ::v-deep
        svg
          path
            fill: $default-gray-medium

      span
        vertical-align: text-top
        margin-left: 5px
        color: $default-purple
        font-size: 0.9rem

    .title
      margin-top: 20px
      margin-bottom: 24px
      height: 40px
      border-left: 13px solid $default-purple-light
      padding: 10px
      font-weight: 700
      font-size: 16px

    .form
      padding: 5px

      &-row
        display: flex
        flex-direction: column
        margin-bottom: 24px
        font-size: 16px

        .invalid
          +default-invalid-input

        &_last
          margin-bottom: 0

        &-label
          font-style: normal
          font-weight: 200
          font-size: 13px
          line-height: 19px
          margin-bottom: 4px

          &_dropdown
            margin-bottom: 4px

        &-field
          display: flex
          align-items: center
          width: 100%

          &-col
            display: flex
            flex-direction: column

          &_without-label
            margin-top: 4px

          &_radio
            height: 32px

          .label
            padding-left: 6px

          ::v-deep
            input
              border-color: #ccc
            .app-select
              border-color: #ccc
              padding: 0
              .dropdown-toggle
                border: none
                height: 40px

            .app-checkbox
              padding: 4px

              &-icon
                margin-right: 0

    .table
      margin-bottom: 40px

      table
        width: 100%

        thead
          background-color: $default-gray-light
          color: #3B5472
          font-size: 13px

          th
            font-weight: 500
            width: 33%
            border: none

            td
              border-bottom: none

        tr
          height: 44px

          &.sub
            td:first-child
              padding-left: 32px

          &:last-child
            td
              border-bottom-width: 2px
          td
            border-bottom: 1px solid $default-purple-light-line


            .fee-input
              display: flex
              align-items: center

              /* Chrome, Safari, Edge, Opera */
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button
                -webkit-appearance: none
                margin: 0


              /* Firefox */
              input[type=number]
                -moz-appearance: textfield


        tfoot
          font-weight: bold

          tr
            td
              border-bottom: 1px solid $default-purple-light-line
              border-top: 1px solid $default-purple-light-line

              &:last-child
                .yen
                  margin-left: 20px

        td
          vertical-align: middle

          .yen
              margin-left: 10px

          &:not(:first-child)
            text-align: right
            input
              text-align: right
</style>
